import { PuzzleType } from "./types";

export const presidentTour: any = {
  id: 1,
  hu: {
    id: 1,
    name: "Az elnök fia",
    description:
      "Eltűnt az Elnök fia!!! Ti buliztatok vele utoljára, segítsetek megtalálni!",
    puzzles: [
      {
        id: 0,
        type: PuzzleType.START,
      },
      {
        id: 1,
        type: PuzzleType.STANDARD,
        shortTitle: "Bevezetés",
        title: "Eltűnt az elnök fia",
        description: `Tegnap éjszaka a bulinegyedben járt az elnök fia, teljesen inkognitóban.
        Véletlenül összebarátkoztatok és úgy döntöttetek, hogy elmentek vele ide-oda bulizni, aztán egyszer csak nyoma veszett.
        Ti nem emlékeztek semmire. Furcsa, mert nem vagytok másnaposok és alig ittatok előző éjjel.
  
        Az elnök írt nektek, kérjétek el a csapostól a levelet. Először az applikációval foglalkozzatok, a levélben lévő segítségek később kellenek majd, ezt az applikáció külön jelezni fogja.
  
        Elolvastátok a levelet?
        IGEN/NEM`,
        images: [require("../../assets/images/president/bevezetes2.jpg")],
        solutionRegExp: /^(IGEN)$/,
        solutionKey: "IGEN",
        hints: [
          {
            id: 0,
            text: "Igen vagy Nem?",
          },
          {
            id: 1,
            text: 'Ha elolvastátok írjátok be, hogy "IGEN", ekkor tovább engedünk :)',
          },
          {
            id: 2,
            text: 'A megoldás "IGEN"',
          },
        ],
      },
      {
        id: 2,
        type: PuzzleType.COUNTER,
        shortTitle: "Ez nem kell megjelenjen",
        title: "Bemelegítés",
        description:
          "A elnök fia eltűnt!! Van 10 percetek inni, ezután teljes gőzzel dolgoznotok kell az ügyön...",
        images: [require("../../assets/images/president/stopper.jpg")],
        amount: 600,
      },
      {
        id: 3,
        type: PuzzleType.STANDARD,
        shortTitle: "A fal",
        title: "Első emlékek",
        description: `Most hogy elkezdtetek gondolkodni, már tudjátok merre mentetek tegnap. A zsinagógánál keressétek a falat, ami volt, de már nincsen.  Keressétek a dátumot, hogy mióta nincs. akkor tudni fogjátok, hogy merre menjetek, oda ahol a kocka el van vetve.`,
        images: [require("../../assets/images/president/elsoemlekek.jpg")],
        solutionRegExp: /^(01.18|0118|)$/,
        solutionKey: "0118",
        hints: [
          {
            id: 0,
            text: "A Zsinagóga falán van egy tábla, azt keressétek és használjátok az elnöktől kapott levelet.",
          },
          {
            id: 1,
            text: "„A felszabadító szovjet hadsereg”",
          },
          {
            id: 2,
            text: 'A megfejtés: "01.18"',
          },
        ],
      },
      {
        id: 4,
        type: PuzzleType.STANDARD,
        shortTitle: "Jó irány",
        title: "A kocka felé, félúton",
        description: `Most már tudjátok, hogy merre van a nagy kocka.  Ahhoz hogy meglegyen merre indultatok tovább. Használjátok az elnöktől kapott utolsó előtti segítséget.`,
        images: [require("../../assets/images/president/9.jpg")],
        solutionRegExp: /^(CARL LUTZ|CARLLUTZ)$/,
        solutionKey: "CARL LUTZ",
        hints: [
          {
            id: 0,
            text: "A nagy Rubik kocka a falon",
          },
          {
            id: 1,
            text: "Kaptál egy segítséget az elnöktől, az utolsó 8 számjegyet helyettesítsd be. Tehát rakd sorrendbe a számokat és ezért a betűkből ki fog jönni egy név.",
          },
          {
            id: 2,
            text: 'A megfejtés: "CARL LUTZ"',
          },
        ],
      },
      {
        id: 5,
        type: PuzzleType.STANDARD,
        shortTitle: "Keress",
        title: "Ki keres, az talál",
        description: `Ügyesek vagytok. Kicsit visszafelé kell mennetek és ahol a két személy megmerevedve egymásra tekint, ott teszi fel a kérdését Carl Lutz.
              Kit keres Carl Lutz?`,
        images: [require("../../assets/images/president/10.jpg")],
        solutionRegExp: /^(ISTEN|ISTENT|GOD)$/,
        solutionKey: "ISTENT",
        hints: [
          {
            id: 0,
            text: "Keressétek a szikladarabot.",
          },
          {
            id: 1,
            text: "A szikladarabra van felírva, hogy kit keres Carl",
          },
          {
            id: 2,
            text: 'A megfejtés: "ISTENT"',
          },
        ],
      },
      {
        id: 6,
        type: PuzzleType.STANDARD,
        shortTitle: "Építész",
        title: "Isten házát emberi kéz alkotta",
        description: `Nagyon jók vagytok, tehát most meg kell keresnünk a nyomok alapján, hogy mi lehetet a következő hely. Isten házát kell keresnünk.
              A legközelebbi a Kazinczy utcában lesz. A falba épített lovaknál forduljatok jobbra.
              Használjátok az elnöktől kapott utolsó segítséget. Aki építette Isten házát, megmondja hova menjetek tovább.`,
        images: [require("../../assets/images/president/11_2_2.jpg")],
        solutionRegExp: /^(LONDON PUB|LONDONPUB)$/,
        solutionKey: "LONDON PUB",
        hints: [
          {
            id: 0,
            text: "A lovak jobbra indulnának",
          },
          {
            id: 1,
            text: "A megfejtés Fejér Lajos. Helyettesítsétek be a betűket a neve sorrendjébe és ki fog jönni a hely neve ahova mennetek kell.",
          },
          {
            id: 2,
            text: 'A megfejtés: "LONDON PUB"',
          },
        ],
      },
      {
        id: 7,
        type: PuzzleType.STANDARD,
        shortTitle: "Bár",
        title: "A pultos a barátod",
        description: `Ez is megvan!  Keressétek meg a London Pubot, ezek szerint itt tartózkodtatok tegnap. Először igyatok valamit, hogy megeredjen a pultos nyelve.
              Kérdezzétek meg a Pultost hogy látott-e esetleg valamit. Mondjátok meg, hogy eltűnt az elnök fia és őt keresitek.
              Beszéltetek a pultossal?
              IGEN/NEM`,
        images: [require("../../assets/images/president/12_2.jpg")],
        solutionRegExp: /^(IGEN)$/,
        solutionKey: "IGEN",
        hints: [
          {
            id: 0,
            text: "Ha nem, akkor siessetek",
          },
          {
            id: 1,
            text: "Igen, beszéltetek?",
          },
          {
            id: 2,
            text: 'A megfejtés: "IGEN"',
          },
        ],
      },
      {
        id: 8,
        type: PuzzleType.COUNTER,
        shortTitle: "Ez nem kell megjelenjen",
        title: "Pihenő",
        description:
          "Ügyesek vagytok, pihenjetek egy kicsit, mielőtt tovább indultok!",
        images: [require("../../assets/images/president/stopper.jpg")],
        amount: 600,
      },
      {
        id: 9,
        type: PuzzleType.STANDARD,
        shortTitle: "Merre",
        title: "Írd be a helyes irányt mutató kép sorszámát",
        description: `Szembe mentünk a forgalommal. Anna után voltunk és az 1957 Time magazin elött balra fordultunk, amit lefedtek Karikó Katalinnal.`,
        images: [
          require("../../assets/images/president/2_incorrect_2.jpg"),
          require("../../assets/images/president/2_correct.jpg"),
          require("../../assets/images/president/2_incorrect_3.jpg"),
          require("../../assets/images/president/2_incorrect_4.jpg"),
        ],
        solutionRegExp: /^(2)$/,
        solutionKey: "2",
        hints: [
          {
            id: 0,
            text: 'A megfejtés: "2"',
          },
        ],
      },
      {
        id: 10,
        type: PuzzleType.STANDARD,
        shortTitle: "Merre",
        title: "Írd be a helyes irányt mutató kép sorszámát",
        description: `Áthajtottunk egy zongorán.`,
        images: [
          require("../../assets/images/president/3_incorrect_2.jpg"),
          require("../../assets/images/president/3_incorrect_3.jpg"),
          require("../../assets/images/president/3_incorrect_4.jpg"),
          require("../../assets/images/president/3_correct.jpg"),
        ],
        solutionRegExp: /^(4)$/,
        solutionKey: "4",
        hints: [
          {
            id: 0,
            text: 'A megfejtés: "4"',
          },
        ],
      },
      {
        id: 11,
        type: PuzzleType.STANDARD,
        shortTitle: "Merre",
        title: "Írd be a helyes irányt mutató kép sorszámát",
        description: `A baloldalon vásárló nagymamák, a jobb oldalon bulizó fiatalok voltak.`,
        images: [
          require("../../assets/images/president/4_correct.jpg"),
          require("../../assets/images/president/4_incorrect_2.jpg"),
          require("../../assets/images/president/4_incorrect_3.jpg"),
          require("../../assets/images/president/4_incorrect_4.jpg"),
        ],
        solutionRegExp: /^(1)$/,
        solutionKey: "1",
        hints: [
          {
            id: 0,
            text: 'A megfejtés: "1"',
          },
        ],
      },
      {
        id: 12,
        type: PuzzleType.STANDARD,
        shortTitle: "Merre",
        title: "Írd be a helyes irányt mutató kép sorszámát",
        description: `Aztán sokáig mentünk egyenesen és halottam hogy valaki kiabál, hogy : „Parkolj, már le a házba aztán gyere, mind az ötvenhetet megisszuk, csak kiveszek még valami pénzt.”`,
        images: [
          require("../../assets/images/president/5_incorrect_2.jpg?v=3"),
          require("../../assets/images/president/5_incorrect_3.jpg?v=3"),
          require("../../assets/images/president/5_correct.jpg?v=3"),
          require("../../assets/images/president/5_incorrect_4.jpg?v=3"),
        ],
        solutionRegExp: /^(3)$/,
        solutionKey: "3",
        hints: [
          {
            id: 0,
            text: 'A megfejtés: "3"',
          },
        ],
      },
      {
        id: 13,
        type: PuzzleType.STANDARD,
        shortTitle: "Merre",
        title: "Írd be a helyes irányt mutató kép sorszámát",
        description: `Jobbra fordultunk, közben harangszót hallottam, aztán mentünk egy kis ideig, majd fuvolát, brácsát, vagy talán egy egész zenekart hallottam egy házból. Ezt követően kirángattak a kocsiból, és megint jobbra vittek, ahol a Kertész utca feliratot láttam, majd végül egy B terv feliratot.`,
        images: [
          require("../../assets/images/president/6_incorrect_2.jpg"),
          require("../../assets/images/president/6_incorrect_3.jpg"),
          require("../../assets/images/president/6_incorrect_4.jpg"),
          require("../../assets/images/president/6_correct.jpg"),
        ],
        solutionRegExp: /^(4)$/,
        solutionKey: "4",
        hints: [
          {
            id: 0,
            text: 'A megfejtés: "4"',
          },
        ],
      },
      {
        id: 14,
        type: PuzzleType.STANDARD,
        shortTitle: "Végállomás",
        title: "Végállomás",
        description: `Ha sikeresen követtétek az elnök fiának szavait eltaláltatok a célhoz. Írjátok be a hely nevét!`,
        images: [require("../../assets/images/president/gyanusitottak.jpg")],
        solutionRegExp: /^(BTERV)$/,
        solutionKey: "BTERV",
        hints: [
          {
            id: 0,
            text: 'A megfejtés: "BTERV"',
          },
        ],
      },
      {
        id: 15,
        type: PuzzleType.STANDARD,
        shortTitle: "Tetthely",
        title: "Váltságdíjért cserébe",
        description: `Ügyesek vagytok, már csak egy valami van hátra. Valószínűleg a fiú már nincs itt, igyatok valamit és miközben kiszolgálnak, mondjátok, hogy: Tudjuk, hogy ti raboltátok el az elnök fiát. Azért vagyunk itt, hogy tudjunk alkudni. Mennyiért adnátok vissza?
              Próbáljatok minnél kisebb összeget összehozni. Majd írjátok be ide az összeget.`,
        images: [require("../../assets/images/president/wattson.jpg")],
        solutionRegExp: /^(42000000|42 MILLIÓ|42 MILLIO|42|42MILLIO)$/, // TODO: find regexp for any /^(.*)$/
        solutionKey: "42000000",
        hints: [
          {
            id: 0,
            text: "Alkudjatok tovább",
          },
          {
            id: 1,
            text: "40 milliónál kevesebbért úgy se adják vissza",
          },
          {
            id: 2,
            text: 'A megoldás "42000000"',
          },
        ],
      },
      {
        id: 16,
        type: PuzzleType.END,
        shortTitle: "Vége",
        title: "Gratulálunk!",
        description:
          "Gratulálunk!  Ügyesek voltatok! Az elnök rendkívül hálás nektek, azt üzeni: Tisztelt hölgyeim és uraim, köszönöm, hogy megmentettétek a fiamat, bízom benne hogy ez egy új barátság kezdete. A legközelebbi évértékelőn megemlítem, ezt a mai napot.",
        url: "https://detectivetour.hu/", // TODO: get url from Miklos
      },
    ],
  },
  en: {
    id: 1,
    name: "Son of the president",
    description:
      "Eltűnt az Elnök fia!!! Ti buliztatok vele utoljára, segítsetek megtalálni!",
    puzzles: [
      {
        id: 0,
        type: PuzzleType.START,
      },
      {
        id: 1,
        type: PuzzleType.STANDARD,
        shortTitle: "Prelogue",
        title: "The President's son has disappeared.",
        description: `Last night, the President's son was in the party district, completely incognito.
        You happened to make friends and decided to go out partying with him, and then suddenly he disappeared.
        You don't remember anything. It's strange, because you're not hungover and you barely drank the night before.
        
        The president sent you a message, ask the bartender for the letter. Focus on the app first. You will need the hints from the presidential letter later. The app will tell you whenever it is time to check the letter again.
        
        Have you received the letter?
        YES/NO`,
        images: [require("../../assets/images/president/bevezetes2.jpg")],
        solutionRegExp: /^(YES|yes)$/,
        solutionKey: "YES",
        hints: [
          {
            id: 0,
            text: "Yes or no?",
          },
          {
            id: 1,
            text: 'If you read it then type in "YES". You can continue after that. :)',
          },
          {
            id: 2,
            text: 'The solution is "YES"',
          },
        ],
      },
      {
        id: 2,
        type: PuzzleType.COUNTER,
        shortTitle: "This shouldn't be visible",
        title: "Warm-up",
        description:
          `The President's son has disappeared!! You have 10 minutes to drink, then you have to work full steam on the case...`,
        images: [require("../../assets/images/president/stopper.jpg")],
        amount: 600,
      },
      {
        id: 3,
        type: PuzzleType.STANDARD,
        shortTitle: "The wall",
        title: "First memories",
        description: `Now that you've started thinking, you remember a bit where you were heading yesterday. At the synagogue, look for the wall that was but is no more. Look for the date since it is no more, then you will know where to go, where the dice are cast.
        
        Translation: “During fascism, this was one of the gates of the Budapest ghetto.
        The Soviet Liberation Army demolished the walls of the ghetto on the .. /…… 1945.”`,
        images: [require("../../assets/images/president/elsoemlekek.jpg")],
        solutionRegExp: /^(01.18|0118|)$/,
        solutionKey: "0118",
        hints: [
          {
            id: 0,
            text: "There is a sign on the wall of the Synagogue, look for it and use the letter from the President.",
          },
          {
            id: 1,
            text: "„The Soviet Liberation Army”",
          },
          {
            id: 2,
            text: 'The answer is "01.18"',
          },
        ],
      },
      {
        id: 4,
        type: PuzzleType.STANDARD,
        shortTitle: "Good direction",
        title: "Towards the cube, halfway",
        description: `Now you know which way the big cube is. To find out which way you were going last night, use the second to last hint from the President.`,
        images: [require("../../assets/images/president/9.jpg")],
        solutionRegExp: /^(CARL LUTZ|CARLLUTZ)$/,
        solutionKey: "CARL LUTZ",
        hints: [
          {
            id: 0,
            text: "The big Rubik cube on the wall.",
          },
          {
            id: 1,
            text: "You got a hint from the president, substitute the last 8 digits. So put the numbers in order and the letters will come up with a name.",
          },
          {
            id: 2,
            text: 'The answer is "CARL LUTZ"',
          },
        ],
      },
      {
        id: 5,
        type: PuzzleType.STANDARD,
        shortTitle: "Search",
        title: "He who seeks shall find",
        description: `You're good. You have to walk back a bit and where two people are frozen in time, staring at each other, is where Carl Lutz asks his question. 
        Who is Carl Lutz looking for? You have to search for a Hungarian word on a rock. It will be at the end of the text.`,
        images: [require("../../assets/images/president/10.jpg")],
        solutionRegExp: /^(ISTEN|ISTENT|GOD)$/,
        solutionKey: "ISTENT",
        hints: [
          {
            id: 0,
            text: "Find the rock.",
          },
          {
            id: 1,
            text: `On the rock is written who Carl is looking for. 

            “...it drives me crazy when I suddenly have to decide who to save. Where is .....?” (excerpt from Carl Lutz's diary)
            `,
          },
          {
            id: 2,
            text: 'The answer is "Isten" (God)',
          },
        ],
      },
      {
        id: 6,
        type: PuzzleType.STANDARD,
        shortTitle: "Architect",
        title: "The house of God was made by human hands",
        description: `You're very good. So now we have to look for clues as to what the next place might be. We must look for the house of God.
        The closest one will be on Kazinczy Street. Turn right at the horse built onto the wall.
        Use the last hint the president gave you. Whoever built the house of God will tell you where to go next.`,
        images: [require("../../assets/images/president/11_2_2.jpg")],
        solutionRegExp: /^(LONDON PUB|LONDONPUB)$/,
        solutionKey: "LONDON PUB",
        hints: [
          {
            id: 0,
            text: "The horse would go to the right. Budapest, Kazinczy u. 29-31, 1075",
          },
          {
            id: 1,
            text: "The answer is “Fejér Lajos”. Put the letters in the order of his name and you will get the name of the place you need to go. (3rd hint in presidential letter.)",
          },
          {
            id: 2,
            text: 'The answer is "LONDON PUB"',
          },
        ],
      },
      {
        id: 7,
        type: PuzzleType.STANDARD,
        shortTitle: "Bar",
        title: "The bartender is your friend",
        description: `You've got this one too! Look for the London Pub, it looks like you were here yesterday. Have a drink first to get the barman find his tongue.
        Ask the barman if he saw anything. Tell him the President's son is missing and you're looking for him.
        Did you talk to the bartender?
        YES/NO`,
        images: [require("../../assets/images/president/12_2.jpg")],
        solutionRegExp: /^(YES)$/,
        solutionKey: "YES",
        hints: [
          {
            id: 0,
            text: "If not, hurry up.",
          },
          {
            id: 1,
            text: "Did you talk to him?",
          },
          {
            id: 2,
            text: 'The answer is "YES"',
          },
        ],
      },
      {
        id: 8,
        type: PuzzleType.COUNTER,
        shortTitle: "This shouldn't be visible",
        title: "Rest",
        description:
          "Well done, get some rest before you move on!",
        images: [require("../../assets/images/president/stopper.jpg")],
        amount: 600,
      },
      {
        id: 9,
        type: PuzzleType.STANDARD,
        shortTitle: "Which way?",
        title: `Write the number of the picture showing the right direction. 
        (You can scroll the images to the right.)`,
        description: `We were going against the traffic. We were after Anna and in front of the 1957 Time magazine we turned left.`,
        images: [
          require("../../assets/images/president/2_incorrect_2.jpg?v=3"),
          require("../../assets/images/president/2_correct.jpg?v=3"),
          require("../../assets/images/president/2_incorrect_3.jpg?v=3"),
          require("../../assets/images/president/2_incorrect_4.jpg?v=3"),
        ],
        solutionRegExp: /^(2)$/,
        solutionKey: "2",
        hints: [
          {
            id: 0,
            text: 'The answer is "2"',
          },
        ],
      },
      {
        id: 10,
        type: PuzzleType.STANDARD,
        shortTitle: "Which way?",
        title: `Write the number of the picture showing the right direction. 
        (You can scroll the images to the right.)`,
        description: `We drove through a piano!`,
        images: [
          require("../../assets/images/president/3_incorrect_2.jpg"),
          require("../../assets/images/president/3_incorrect_3.jpg"),
          require("../../assets/images/president/3_incorrect_4.jpg"),
          require("../../assets/images/president/3_correct.jpg"),
        ],
        solutionRegExp: /^(4)$/,
        solutionKey: "4",
        hints: [
          {
            id: 0,
            text: 'The answer is "4"',
          },
        ],
      },
      {
        id: 11,
        type: PuzzleType.STANDARD,
        shortTitle: "Which way?",
        title: `Write the number of the picture showing the right direction. 
        (You can scroll the images to the right.)`,
        description: `Grandmas shopping on the left, youngsters partying on the right.`,
        images: [
          require("../../assets/images/president/4_correct.jpg"),
          require("../../assets/images/president/4_incorrect_2.jpg"),
          require("../../assets/images/president/4_incorrect_3.jpg"),
          require("../../assets/images/president/4_incorrect_4.jpg"),
        ],
        solutionRegExp: /^(1)$/,
        solutionKey: "1",
        hints: [
          {
            id: 0,
            text: 'The answer is "1"',
          },
        ],
      },
      {
        id: 12,
        type: PuzzleType.STANDARD,
        shortTitle: "Which way?",
        title: `Write the number of the picture showing the right direction. 
        (You can scroll the images to the right.)`,
        description: `Then we drove straight for a long time and I heard someone shouting, "C’mon withdraw some money, finally for God’s sake. Let’s go! We'll drink all fifty-seven!!!"`,
        images: [
          require("../../assets/images/president/5_incorrect_2.jpg"),
          require("../../assets/images/president/5_incorrect_3.jpg"),
          require("../../assets/images/president/5_correct.jpg"),
          require("../../assets/images/president/5_incorrect_4.jpg"),
        ],
        solutionRegExp: /^(3)$/,
        solutionKey: "3",
        hints: [
          {
            id: 0,
            text: 'The answer is "3"',
          },
        ],
      },
      {
        id: 13,
        type: PuzzleType.STANDARD,
        shortTitle: "Which way?",
        title: `Write the number of the picture showing the right direction. 
        (You can scroll the images to the right.)`,
        description: `We turned right, and I heard bells ringing, and then we walked for a while, and then I heard flute, viola, or maybe a whole orchestra from a house. After that I was dragged out of the car and taken to the right again, where I saw a sign for Kertész Street, and finally a sign for Plan B.`,
        images: [
          require("../../assets/images/president/6_incorrect_2.jpg"),
          require("../../assets/images/president/6_incorrect_3.jpg"),
          require("../../assets/images/president/6_incorrect_4.jpg"),
          require("../../assets/images/president/6_correct.jpg"),
        ],
        solutionRegExp: /^(4)$/,
        solutionKey: "4",
        hints: [
          {
            id: 0,
            text: 'The answer is "4"',
          },
        ],
      },
      {
        id: 14,
        type: PuzzleType.STANDARD,
        shortTitle: "Terminus",
        title: "Terminus",
        description: `If you have successfully followed the words of the President's son, you have reached your destination. Enter the name of the place!`,
        images: [require("../../assets/images/president/gyanusitottak.jpg")],
        solutionRegExp: /^(BTERV)$/,
        solutionKey: "BTERV",
        hints: [
          {
            id: 0,
            text: 'The answer is "BTERV"',
          },
        ],
      },
      {
        id: 15,
        type: PuzzleType.STANDARD,
        shortTitle: "Crime scene",
        title: "In exchange for ransom",
        description: `Well done, just one more thing! Probably the son is no longer here, have a drink and while you're being served, say: We know you kidnapped the president's son. We're here to make a deal. For how much will you give him back?
        Try to get as little amount as you can. Write the amount here.`,
        images: [require("../../assets/images/president/wattson.jpg")],
        solutionRegExp: /^(42000000|42 MILLIÓ|42 MILLIO|42|42MILLIO)$/,
        solutionKey: "42000000",
        hints: [
          {
            id: 0,
            text: "Keep bargaining.",
          },
          {
            id: 1,
            text: "They won't give him back for less than 40 million",
          },
          {
            id: 2,
            text: 'The solution is "42000000"',
          },
        ],
      },
      {
        id: 16,
        type: PuzzleType.END,
        shortTitle: "Finished",
        title: "Congratulations!",
        description:
          `Congratulations!
          Congratulations! Well done! The President is very grateful to you, he says: "Ladies and gentlemen, thank you for saving my son, I trust this is the beginning of a new friendship. I will mention this day at the next annual review.`,
        url: "https://detectivetour.hu/", // TODO: get url from Miklos
      },
    ],
  }
};
